.filtro-daterangepicker {
  grid-column: 3/4;
}

.filtro-daterangepicker-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.datepicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* .filtro-daterangepicker-background {
  background-color: var(--gray-green-light);
  border-radius: 10px;
  /* padding: 6px 0 6px 6px; */
/* } */

.filter-icon {
  justify-self: flex-end;
  margin-right: 10px;
  font-size: 1.2em;
}

.filter-icon path {
  stroke: var(--primary);
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (min-width: 130em) {
  .filter-icon {
    font-size: 1.6em;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (max-width: 30em) {
  .filter-icon {
    margin-right: 0;
  }

  .datepicker,
  .filtro-daterangepicker-icon {
    gap: 6px;
  }
}
