.container-cadastro {
  background-color: var(--primary-light);
  margin: 0 auto;
  font-family: var(--font-family-roboto);
  color: var(--gray);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  padding-bottom: 30px;
  min-height: 110vh;
}
.container-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.div-logo {
  margin-bottom: 0px;
}

.logo-cadastro {
  display: block;
  max-width: 30%;
  margin: auto;
}

@media (max-width: 57em) {
  .logo-cadastro,
  .form-div {
    max-width: 50%;
  }
}

.perspective-container {
  perspective: 800px;
}
.perspective-element {
  transform: translateZ(0px) rotateX(25deg);
}
