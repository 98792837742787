/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 16px;
  top: 15px;
  left: initial;
  right: 20px;
  visibility: hidden;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--black);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--gray);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--gray);
}

.bm-cross-hover {
  background: black;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--search);
  padding: 2.5em 0;
  font-size: 1.15em;
  margin-left: 40px;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item,
.bm-item:active,
.bm-item:visited,
.bm-item:hover,
.bm-item:focus,
.bm-item:focus-visible {
  padding: 0.8em;
  color: var(--label);
  display: inline-block;
  text-decoration: none;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
}

.selected1 {
  color: var(--label-bold) !important;
  font-weight: 600;
}

.iconUser {
  color: var(--label);
  height: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Phone */

@media (max-width: 27em) {
  .bm-burger-button {
    visibility: visible;
  }

  .bm-menu-wrap {
    width: 200px !important;
  }
}
