@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,600|IBM+Plex+Sans:400");
@import url("https://fonts.cdnfonts.com/css/roboto");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: var(--font-family-roboto) !important;
  line-height: 1.5;
}

h1,
h2 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

ul {
  margin-bottom: 1rem;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-general {
  background-color: var(--primary-light);
  margin: 0 auto;
  min-width: 32rem;
}

.main,
.main-user {
  align-items: flex-start;
  border: 1px none;
  box-shadow: 0px 8px 3px #1da58401;
  padding: 100px 44px 44px 44px;
  min-height: 96vh;
}

.screen a {
  display: contents;
  text-decoration: none;
}
/* 
.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
} */

@media (min-width: 130em) {
  .main {
    padding: 130px 44px 44px 44px;
  }
}
