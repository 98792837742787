.footer-main {
  color: var(--label);
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@media (min-width: 130em) {
  .footer-main {
    font-size: var(--font-size-xl);
  }
}
