.button-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-form-hover:hover,
.button-form-hover:focus {
  text-decoration: underline;
}

.button:first-child {
  padding: 10px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--primary-light-2);
  border: 1px solid var(--primary-light-2);
  border-radius: 8px;
  padding: 6px;
  color: var(--primary);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.button:disabled,
.contactus-button:disabled,
.contactus-button-color:disabled {
  /* color: #ccc;
  background-color: var(--primary-light);
  border: none; */
  /* cursor: not-allowed; */
  opacity: 0.65;
}

.link {
  display: flex;
  width: 100%;
  background-color: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

.google-icon {
  width: 20px;
  margin-right: 26px;
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 34px;
}

.contactus-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-button);
  background-color: var(--primary-button);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 100px;
  min-height: 50px;
  color: var(--background);
  font-size: var(--font-size-m);
  font-weight: 500;
  white-space: nowrap;
}

.contactus-button:hover {
  border-color: var(--primary) !important;
  background: var(--primary) !important;
  transition: all 0.25s ease-out;
  color: var(--gray-10) !important;
}

.contactus-button:active,
.contactus-button:focus,
.contactus-button:focus-within,
.contactus-button:focus-visible {
  border-color: var(--primary) !important;
  background: var(--primary) !important;
  color: var(--gray-10) !important;
  box-shadow: none !important;
  outline: none;
}
