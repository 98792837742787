@import "bootstrap/dist/css/bootstrap.min.css";

.dropdown {
  height: auto !important;
}

.user-active:hover path,
.user-active:focus path {
  fill: var(--primary);
  stroke-width: 0.2;
}

p {
  margin-bottom: 0;
}
.header-logo {
  min-width: 180px;
  height: 58px;
}

.show > .dropdown-menu {
  max-height: 900px !important ;
  visibility: visible !important;
}

.dropdown-menu {
  box-shadow: 0px 6px 7px #212b360a;
  /* inset: 64px -30px auto auto !important; */
  transform: none !important;
  border: none !important;
  max-height: 0 !important;
  visibility: hidden !important;
  transition: all 0.5s ease-in-out !important;
  overflow: hidden !important;
}

.dropdown-item {
  padding: 5px 20px;
  font-size: var(--font-size-m) !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--primary-light-2) !important;
  font-size: var(--font-size-m) !important;
}

.logo {
  min-width: 100%;
  height: 100%;
}

.header {
  align-items: center;
  background-color: var(--background);
  box-shadow: 0px 6px 7px #212b360a;
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 20px 44px;
  position: fixed;
  z-index: 50;
}

.menu {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.menu li a:link,
.menu li a {
  display: inline-block;
  text-decoration: none;
  font-size: var(--font-size-xl);
  transition: all 0.3s;
  color: var(--gray);
  cursor: pointer;
}

.menu li a:visited {
  color: var(--gray);
  text-decoration: none;
}

.menu li a:hover {
  color: var(--label-bold);
}

.menu li:hover {
  color: var(--label-bold);
}

.selected {
  /* margin-top: 8px; */
  color: var(--label-bold) !important;
  text-decoration: none !important;
}

.selected:after {
  content: "";
  display: block;
  bottom: -42px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  height: 7px;
  width: 40px;
  border-radius: 10px 10px 0 0;
  background: var(--primary);
  margin-top: -8px;
}

.icon {
  height: 100%;
  color: var(--gray);
  width: 24px;
  height: 25px;
}

.divisor {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  gap: 10px;
  height: 35px;
  transform: rotate(0.28deg);
  width: fit-content;
}

.usuario {
  align-items: center;
  align-self: stretch;
  border: 1px none;
  display: flex;
  gap: 4px;
  width: fit-content;
}

.usuario a {
  display: flex !important;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

/* Big Screen */

@media (min-width: 130em) {
  .header-logo {
    min-width: 230px;
    height: 100px;
  }

  .menu {
    gap: 8rem;
  }

  .menu li a {
    font-size: var(--font-size-xxl) !important;
  }

  .selected:after {
    bottom: -55px;
  }

  .usuario > svg:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
  }

  .usuario > svg:nth-child(2) {
    width: 16px !important;
    height: 16px !important;
  }
}

/* Tablet */

@media (max-width: 55em) {
  .header {
    padding: 10px 22px;
  }

  .header-logo {
    min-width: 140px;
    height: 42px;
  }

  .frame-1 > h1 {
    font-size: var(--font-size-m);
  }

  .menu {
    gap: 1.6rem;
    padding-left: 10px;
  }

  .menu li a:link,
  .menu li a {
    font-size: var(--font-size-m);
  }

  .selected:after {
    bottom: -28px;
  }
}

/* Phone */

@media (max-width: 30em) {
  .header {
    padding: 12px 12px;
  }

  .header-logo {
    min-width: 80px;
    height: 26px;
  }

  .menu {
    gap: 0.8rem;
  }

  .menu li a:link,
  .menu li a {
    font-size: var(--font-size-s);
  }

  .usuario a > svg {
    width: 10px;
  }

  .usuario a > p {
    font-size: var(--font-size-xs);
  }
}
