@import "rsuite/dist/rsuite.css";

:root {
  --rs-input-focus-border: var(--primary);
  --rs-state-focus-shadow: 0 0 0 3px var(--primary-light-2);
  --rs-btn-link-text: var(--primary);
  --rs-btn-primary-bg: var(--primary);
  --rs-bg-active: var(--primary);
  --rs-calendar-cell-selected-hover-bg: var(--primary);
  --rs-calendar-range-bg: var(--primary-light-2);
  --rs-state-hover-bg: var(--primary-light-2);
  --rs-listbox-option-hover-text: var(--primary);
  --rs-listbox-option-hover-bg: var(--primary-light-2);
  --rs-text-primary: var(--label);
  --rs-btn-primary-hover-bg: var(--primary);
  --rs-btn-link-hover-text: var(--primary);
  --rs-picker-value: var(--primary);
  --rs-border-primary: var(--border);
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 12px;
}

.rs-picker-menu {
  font-size: 14px;
}

@media (min-width: 130em) {
  .rs-stack-item input,
  .rs-picker-toggle-placeholder {
    font-size: 22px !important;
    padding: 10px;
    height: 100%;
  }

  .rs-picker-daterange-header {
    font-size: 18px;
  }

  .rs-btn-xs,
  .rs-calendar-body,
  .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-content {
    font-size: 16px;
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 26px;
  }

  .rs-stack-item svg {
    font-size: 1.8rem;
  }
}
