:root {
  --background: #ffffff;
  --black: #000000;
  --border: #dbe9f5;
  --search: #9ea6ad;
  --gray: #8498ae;
  --gray-40: #9a9eb2;
  --gray-light: #a9b6c4;
  --gray-10: #ebecf2;
  --gray-green: #708680;
  --gray-green-light: #f2f6f2;
  --label: #172b3e;
  --label-bold: #232a2f;
  --background-green: rgb(7, 83, 61);
  --primary: #1da584;
  --primary-button: rgb(15, 201, 155);
  --primary-line: rgb(86, 116, 101);
  --primary-light-2: #1da5831f;
  --primary-light: #1da5840a;
  --red: red;

  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xs: 10px;
  --font-size-xl: 18px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 54px;
  --font-size-extra: 62px;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-roboto-mono: "Roboto Mono", monospace;
}
.x12px {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.x14px {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.gray-bold {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.x28px-bold {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
}

.x18px-bold {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
}

@media (max-width: 55em) {
  .gray-bold {
    font-size: var(--font-size-m);
  }
}

@media (max-width: 30em) {
  .gray-bold {
    font-size: var(--font-size-s);
  }

  .x28px-bold {
    font-size: var(--font-size-xl);
  }

  .x14px {
    font-size: var(--font-size-xs);
  }
}
