.contact-seawire-contact {
  display: flex;
  font-family: var(--font-family-roboto);
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px none;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.overlap-group1 {
  align-items: center;
  background-color: var(--background);
  display: flex;
  gap: 112px;
  padding: 0 60px 0 0;
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  max-width: 408px;
  min-width: 200px;
}

.image-custom {
  /* background-image: url(../../assets/contactus.jpeg); */
  background-color: var(--primary-light-2);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  filter: grayscale(40%);
  background-blend-mode: screen;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.contact-us {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  width: 610px;
  min-width: 100px;
}

.title {
  color: var(--gray);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  margin-top: 12px;
  white-space: nowrap;
}

.first-name-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 20px;
  min-height: 68px;
  width: 100%;
}
.input-custom,
.input-custom:focus-visible,
.input-custom:hover {
  background-color: var(--gray-10);
  outline: none;
  color: var(--label);
}

::placeholder {
  color: var(--gray-40);
}

.first-name {
  width: 100%;
  border: none;
  white-space: nowrap;
}

.overlap-group-custom {
  align-items: flex-start;
  background-color: var(--gray-10);
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 15px;
}

.overlap-group-custom:focus-within {
  border-color: var(--primary-line);
  outline: none;
  border-color: var(--primary-line);
  box-shadow: 0 0 3px var(--primary-line);
}

.contact-us-item-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  min-height: 68px;
  width: 100%;
}

.contact-us-item-1.message {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  min-height: 152px;
  width: 100%;
}

.contact-us-item-1.message .overlap-group-1 {
  height: 120px;
  align-items: flex-start;
}

.roboto-normal-blueberry-16px {
  color: var(--primary-button);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-gray-chateau-16px {
  color: var(--gray-40);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.succsess-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.msg-success {
  color: var(--primary-button);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .overlap-group1 {
    gap: 15px;
  }

  .image-custom {
    min-width: 90%;
    height: 100%;
  }

  .contact-us {
    max-width: 300px;
  }
}
@media screen and (max-width: 980px) {
  .overlap-group1 {
    gap: 15px;
    padding: 0 60px 0 0;
  }
}

@media screen and (max-width: 600px) {
  .overlap-group1 {
    gap: 15px;
  }

  .image-custom {
    width: 85%;
    height: 100%;
  }
}

@media screen and (max-width: 570px) {
  .overlap-group1 {
    gap: 10px;
    padding: 0 30px 0 0;
  }

  .title {
    font-size: var(--font-size-xxl);
  }
  .contact-us {
    max-width: 180px;
  }

  .image-custom {
    width: 80%;
    height: 90%;
  }

  .contactus-button {
    min-width: 70px;
    min-height: 35px;
  }
}

@media screen and (max-width: 425px) {
  .overlap-group1 {
    flex-direction: column;
    padding: 10px 20px;
  }

  .image-placeholder {
    max-width: 200px;
    max-height: 200px;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .contact-us {
    max-width: 316px;
  }

  .overlap-group-custom {
    max-height: 40px;
  }

  .contact-us-item-1.message .overlap-group-1 {
    max-height: 100px;
  }

  .msg-success {
    font-size: var(--font-size-s);
  }
}
