.chart-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px none;
  padding-top: 20px;
  width: 100%;
}

.chart-2 {
  border: 1px none;
  width: 90%;
  height: 300px;
}

.ranking {
  height: 500px;
}

/* text[name="6120502"] {
  fill: black !important;
  transform: translateX(180px) !important;
} */

.mystyle {
  fill: black !important;
  transform: translateX(180px) !important;
}
.recharts-tooltip-wrapper,
.recharts-default-tooltip {
  outline: none !important;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
}

@media (min-width: 130em) {
  .recharts-tooltip-wrapper,
  .recharts-default-tooltip {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 57em) {
  .chart-2 {
    height: 150px;
  }
}

.no-data-msg {
  padding: 0 20px 20px 20px;
  font-size: var(--font-size-l);
  font-family: inherit;
}

@media (max-width: 48rem) {
  .chart-2 {
    width: 95%;
  }
  .recharts-tooltip-wrapper,
  .recharts-default-tooltip {
    max-width: 200px !important;
  }
  .no-data-msg {
    font-size: var(--font-size-m);
  }
}

@media (min-width: 91rem) {
  .no-data-msg {
    font-size: var(--font-size-xl);
  }
}
