#ym {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 500;
  color: var(--label);
}

.divSvg {
  position: relative;
  top: 0px;
  left: -282px;
}

.divSvg-container {
  display: flex;
  flex-direction: column;
  gap: 200px;
  position: absolute;
}

/* .svg-timeline {
  width: 100% !important;
} */

.svg-timeline {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  gap: 20px;
  top: 700px;
  left: 30px;
  width: 100% !important;
}

.rc-slider-handle:active,
.rc-slider-handle:hover,
.rc-slider-handle:focus,
.rc-slider-handle-click-focused:focus,
.rc-slider-handle-draggin {
  box-shadow: var(--gray-light) 0px 0px 0px 4px !important;
  border-color: var(--primary);
}

.rc-slider-handle {
  margin-top: 0;
}

.svg-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 5px 0;
}

.svg-timeline p {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 500;
  color: var(--label);
}

#svg-list svg {
  left: 10px;
  top: -5px;
}

.svg-brasil path,
.svg-title path {
  fill: none;
  stroke: black;
  pointer-events: all;
}

.svg-brasil path:hover {
  transform-origin: 400px 400px;
  animation: aumenta ease-out 800ms;
  /* fill: white; */
}

@keyframes aumenta {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(1.1) scaleY(1.1);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
