*,
::after,
::before {
  box-sizing: border-box;
}

video {
  object-fit: contain !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.lading-page {
  background-color: var(--background-green);
  margin: 0 auto;
  min-width: 32rem;
}

.navbar {
  align-items: center;
  display: flex;
  width: 100%;
  position: fixed !important;
  z-index: 50;
  padding: 0.5rem 1rem;
  background-color: var(--background-green) !important;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-brand {
  width: 180px;
  height: 58px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-right: 0 !important;
  font-size: var(--font-size-l) !important;
  color: var(--bs-navbar-brand-color) !important;
}

.navbar-brand-img {
  height: 100%;
  width: 100%;
}

.navbar .container {
  border-bottom: solid 1px var(--primary-line);
  padding: 20px 15px;
}

.container {
  width: 100%;
  padding: 44px 44px 44px 44px !important;
  max-width: 100% !important;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse:not(.show) {
  display: none;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.nav-link {
  display: block;
  font-size: var(--font-size-xl) !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--gray-light);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--gray-10);
}

.navbar-nav a {
  margin: 0 0 0 30px;
  cursor: pointer;
}

/* - - Product (Page)
========================================== */

/* - - - Landing
========================================== */
#cover {
  padding-top: 250px;
  background-size: cover;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 10px;
}

#cover .container,
#cover .navbar {
  position: relative;
  z-index: 2;
}

#cover-text {
  padding-top: 50px;
  padding-bottom: 150px;
  z-index: 2;
}

#cover-text > h1 {
  font-size: var(--font-size-xxxxl);
  font-weight: 200;
}

#cover-text > p {
  color: var(--gray-light);
  font-size: var(--font-size-xl);
}

.video-teaser-overlay-hr {
  position: absolute;
  top: 60%;
  left: 38%;
  width: 205%;
  height: 230%;
  background: linear-gradient(
    90deg,
    var(--background-green) 15%,
    rgb(7, 83, 61, 0) 45%,
    rgb(7, 83, 61, 0) 55%,
    var(--background-green) 80%
  );
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
}

.video-teaser-overlay-vr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    180deg,
    var(--background-green) 20%,
    rgb(7, 83, 61, 0) 45%,
    rgb(7, 83, 61, 0) 55%,
    var(--background-green) 80%
  );
  z-index: 1;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

#video-teaser {
  position: absolute;
  top: 50%;
  left: 40%;
  width: 200%;
  height: 200%;
  z-index: -1;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}

#image-teaser {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200% !important;
  max-width: 200% !important;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}

#features {
  position: relative;
  background-size: cover;
  overflow: hidden;
  margin: 50px 0;
}

#features img {
  image-rendering: -webkit-optimize-contrast;
}

@media (max-width: 991.98px) {
  #cover {
    margin-bottom: 0px;
  }

  #cover-text {
    padding-bottom: 0px;
  }
  #video-teaser {
    position: relative;
    left: 53%;
    width: 150%;
    height: 150%;
    z-index: -1;
    opacity: 0.5;
  }

  #image-teaser {
    position: relative;
    width: 150%;
    max-width: 150% !important;
    z-index: -1;
    opacity: 0.5;
  }
}

#video {
  background: linear-gradient(#0b0b0f 60%, #ffffff 40%);
}

.category-block {
  padding: 15px 10px 0 10px;
}

.category-block:hover {
  background: var(--blue);
  transition: all 0.5s ease-out;
}

.category-block svg {
  fill: var(--gray);
  margin: 4px 15px 0 0;
  width: 48px;
  height: 48px;
}

.category-block:hover svg {
  fill: var(--white);
  transition: all 0.2s ease-out;
}

.category-block h6 {
  margin-bottom: 0;
}

.category-block:hover h6,
.category-block:hover p {
  color: var(--white) !important;
  transition: all 0.2s ease-out;
}

.category-block-stat {
  padding: 15px 10px 0 10px;
  margin-bottom: 50px;
}

.category-block-stat svg {
  margin: 4px 30px 0 0;
  width: 100px;
}

.category-block-stat-new {
  padding: 15px 10px 0 10px;
  margin-bottom: 50px;
}

.category-block-stat-new svg {
  margin: 0 30px 0 0;
  width: 70px;
  height: 70px;
}

.category-block-stat-new p.small {
  margin-bottom: 0;
}

.category-block-stat-new h6 {
  margin-bottom: 5px;
}

.category-block-stat h5 {
  margin-top: 50px;
  vertical-align: center;
}

.row-block-padding {
  padding: 80px 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 50px;
  padding-left: 15px;
}

/* Buttons */

.btn {
  font-size: var(--font-size-l) !important;
  padding: 10px 30px !important;
  border-radius: 2px !important;
  margin-bottom: 5px !important;
}

.btn-primary {
  border-color: var(--gray) !important;
  background: var(--gray) !important;
  color: var(--gray-10) !important;
}

.btn-primary:hover {
  border-color: var(--gray-40) !important;
  background: var(--gray-40) !important;
  transition: all 0.25s ease-out;
  color: var(--gray-10) !important;
}

.btn-primary:active,
.btn-primary:focus {
  border-color: var(--primary-button) !important;
  background: var(--primary-button) !important;
  color: var(--gray-10) !important;
  box-shadow: none !important;
}

/* Main Features section styles
========================================== */

.title-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title-custom h2 {
  color: var(--background);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  letter-spacing: 0.8px;
  line-height: 1.2;
  max-width: 540px;
  font-family: var(--font-family-roboto-mono);
}

.badge-info {
  font-size: var(--font-size-l);
  color: var(--gray-light);
  background: var(--gray-green);
  margin-bottom: 22px;
}

.badge-title {
  font-family: var(--font-family-roboto-mono);
  font-weight: 400;
  border-radius: 4px;
  padding: 5px 10px;
  width: fit-content;
}

.container-background {
  background-color: var(--primary-light);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  padding: 70px;
}

.text-big {
  font-size: var(--font-size-xl);
  font-weight: 400;
  color: var(--gray-light);
  margin-bottom: 20px;
  max-width: 540px;
}

/* countUp section styles
========================================== */
#countUp {
  overflow: hidden;
  margin: 50px 0 -100px 0;
}
.container.countUp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: var(--font-size-xxl);
  font-family: var(--font-family-roboto-mono);
  color: var(--gray-40);
}

.countUp-cnaes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  text-align: center;
  position: relative;
  top: -250px;
  left: 50px;
}

.countUp-cnaes.countUp-cnaes-2 {
  position: relative;
  left: -30px;
}

.countUp-cnaes span {
  font-size: var(--font-size-xxxl);
  color: var(--gray-green);
  transition: color 3s ease-in-out;
  font-weight: 600;
}

.countUp-item {
  position: relative;
  width: 350px;
}

.countUp-item-2 {
  position: relative;
  width: 350px;
  height: 350px;
  margin-left: -90px;
}

.iphone-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.gadgets-img {
  max-width: 400px;
}

/* Prototype section styles
========================================== */
.try-it-prototype {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.try-it-prototype h2 {
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  color: var(--gray-40);
  margin-bottom: 20px;
  font-family: var(--font-family-roboto-mono);
  word-wrap: break-word;
  max-width: 500px;
}

/* Footer section styles
========================================== */

#footer .container {
  padding-top: 50px;
  padding-bottom: 30px;
  border-top: solid 1px var(--primary-line);
}

#footer .flex {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-start;
}

#footer li {
  margin-bottom: 10px;
}

#footer .copyright {
  margin-top: 30px;
  color: var(--gray-green);
  font-size: var(--font-size-m);
  word-spacing: 2px;
}

#footer a {
  text-decoration: none;
}

#footer ul {
  font-size: var(--font-size-m);
}

#footer p {
  font-size: var(--font-size-l);
  color: var(--gray-light);
  margin-bottom: 8px;
}

#footer .icon {
  margin-right: 10px;
  width: 20px;
}

#footer .icon path {
  fill: var(--background);
}

@media (min-width: 130em) {
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1740px !important;
  }

  .navbar-brand {
    min-width: 250px;
    height: 120px;
  }

  .video-teaser-overlay-hr {
    transform: translateX(-38%) translateY(-50%);
    left: 17%;
  }

  .nav-link {
    font-size: var(--font-size-xxxl) !important;
  }

  #cover-text > h1 {
    font-size: var(--font-size-extra);
  }

  #cover-text > p,
  .btn,
  .badge-info,
  .text-big,
  #footer p {
    font-size: var(--font-size-xxl) !important;
  }

  .title-custom h2,
  .text-big,
  .try-it-prototype h2 {
    font-size: var(--font-size-xxxxl);
    max-width: 700px;
  }

  .countUp-item,
  .countUp-item-2 {
    width: 550px;
  }

  .countUp-item-2 {
    height: 550px;
  }

  .countUp-cnaes {
    top: -335px;
    left: 133px;
  }

  .countUp-cnaes.countUp-cnaes-2 {
    left: 54px;
  }

  #tryit > div > div > div > img {
    width: 480px;
  }

  #footer > div > div.flex > div:nth-child(1) > a > img {
    height: 80px;
  }

  #footer ul {
    font-size: var(--font-size-xl);
  }

  #footer .copyright {
    font-size: var(--font-size-xl) !important;
  }
}

@media (max-width: 992px) {
  .video-teaser-overlay-vr {
    height: 140%;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 425px) {
  .lading-page {
    max-width: 100% !important;
  }
  #cover {
    max-width: 100% !important;
    padding-top: 150px;
  }

  #cover-text {
    padding-top: 0;
  }

  #cover-text > h1 {
    font-size: var(--font-size-xxl);
  }

  .big {
    font-size: var(--font-size-m) !important;
  }

  .btn {
    font-size: var(--font-size-m) !important;
    padding: 8px 20px !important;
  }

  .title-custom h2 {
    font-size: var(--font-size-xl);
    min-width: 270px;
  }

  .text-big {
    min-width: 270px;
  }

  .badge-title {
    font-size: var(--font-size-m);
  }

  .container.countUp {
    font-size: var(--font-size-xl);
    flex-direction: column;
    align-items: center;
  }

  .countUp-cnaes {
    top: -200px;
    left: 20px;
  }

  .countUp-cnaes.countUp-cnaes-2 {
    top: -240px;
    left: 20px;
  }

  .countUp-cnaes p {
    max-width: 150px;
  }

  .countUp-item,
  .countUp-item-2 {
    margin-top: -50px;
    width: 300px;
  }

  .countUp-item-2 {
    position: relative;
    margin-left: 0;
    margin-top: -150px;
  }

  .try-it-prototype {
    flex-direction: column;
    align-items: center;
    text-align: flex-start;
    gap: 30px;
  }

  .try-it-prototype h2 {
    font-size: var(--font-size-xxl);
  }
  .try-it-prototype img {
    width: 80%;
  }

  .iphone-div {
    margin-right: 0;
  }

  .title-custom h2 {
    font-size: var(--font-size-m);
    min-width: 50px;
  }

  .iphone-div img {
    width: 40%;
  }

  #footer {
    max-width: 100% !important;
    font-size: var(--font-size-xs);
  }
  #footer ul {
    font-size: var(--font-size-xs);
    word-wrap: break-word;
  }
  #footer .container {
    padding-bottom: 20px;
  }
  #footer .copyright {
    font-size: var(--font-size-xs);
  }

  #footer .flex {
    max-width: 100% !important;
    gap: 18px;
  }
  .navbar {
    max-width: 100% !important;
  }

  .flex .navbar-brand img {
    width: 80px;
  }

  .flex .div-contact {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-teaser-overlay-vr {
    height: 140%;
  }
}
