.modal-custome {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: var(--font-size-m);
  position: relative;
}

.modal-custome.modal-custome-show h1 {
  font-size: var(--font-size-xl);
}

.modal-custome-show {
  position: fixed;
  z-index: 500;
  width: 90%;
  left: 5%;
  top: 30%;
  transition: all 0.3s ease-out;
}

.modal-custome-ranking {
  position: absolute;
  width: 15%;
  z-index: 100;
  transform: translateX(-50%);
  transition: transform 400ms ease 0s;
}

@media (min-width: 130em) {
  .modal-custome {
    font-size: var(--font-size-xl);
  }

  .modal-custome-ranking {
    width: 16%;
  }
}

@media (max-width: 48em) {
  .modal-custome {
    font-size: var(--font-size-s);
  }

  .modal-custome-ranking {
    width: 40%;
  }
}
