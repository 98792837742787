/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.title-auth {
  color: var(--gray);
  display: block;
  text-align: center;
  margin: 0 auto 20px auto;
}
.form-div {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30%;
}

.form {
  width: 100%;
  margin: 16px 0 6px;
}

.success-register {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.success-register-link,
.success-register-link:link {
  margin-left: 5px;
  /* text-decoration: none; */
  color: inherit;
  cursor: pointer;
}

.success-register-link:hover,
.success-register-link:focus {
  text-decoration: underline;
  color: inherit;
  font-weight: 800;
}

.input-div {
  width: 100%;
  margin-bottom: 30px;
}

.label {
  font: inherit;
}

.input-register,
.input-register:active,
.input-register:hover {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 2px solid var(--gray-light); */
  border-radius: 2px;
  background-color: inherit !important;
  background-image: none !important;
  font: inherit !important;
  color: inherit !important;
  padding: 6px 0;
  display: block;
  box-sizing: border-box;
  appearance: none;
}

.error:focus {
  border-bottom: 3px solid var(--red);
}

.input-div-2 {
  display: flex;
  align-items: center;
}

.password-strength {
  margin-bottom: 20px;
}
.strength-validation {
  display: flex;
  gap: 10px;
  list-style: none;
  font-size: var(--font-size-m);
}

.green {
  color: var(--primary);
}

.gray {
  color: var(--gray);
}

.error-message {
  color: var(--red);
}

.forgot-password-button,
.msg-change-password {
  display: flex;
  justify-content: center;
  margin: 16px 0 2px;
}

@media (min-width: 130em) {
  .strength-validation {
    gap: 20px;
  }

  .title-auth {
    font-size: var(--font-size-xxxl) !important;
  }

  .label p {
    font-size: var(--font-size-xl) !important;
  }

  .input-register {
    font-size: var(--font-size-xl) !important;
  }
}

@media (max-width: 30em) {
  .input-register,
  .input-register:focus,
  .input-register:active,
  .input-register:hover {
    font-size: var(--font-size-s) !important;
  }

  input:-webkit-autofill::first-line {
    color: var(--gray);
    font-size: var(--font-size-xs);
    font-family: var(--font-family-roboto);
  }

  .strength-validation {
    font-size: var(--font-size-s);
  }

  .success-register {
    font-size: var(--font-size-s);
    display: block;
    text-align: center;
    padding: 50px;
  }

  .msg-change-password {
    font-size: var(--font-size-s);
    text-align: center;
  }
}
.msg-error {
  color: var(--red);
}

.input-div-2 {
  border-bottom: 2px solid var(--gray-light);
}

.icon {
  margin-right: 0;
}
