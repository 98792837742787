@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600|IBM+Plex+Sans:400);
@import url(https://fonts.cdnfonts.com/css/roboto);
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
* {
  box-sizing: border-box;
  margin: 0;
}

html {
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: var(--font-family-roboto) !important;
  line-height: 1.5;
}

h1,
h2 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

ul {
  margin-bottom: 1rem;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-general {
  background-color: var(--primary-light);
  margin: 0 auto;
  min-width: 32rem;
}

.main,
.main-user {
  align-items: flex-start;
  border: 1px none;
  box-shadow: 0px 8px 3px #1da58401;
  padding: 100px 44px 44px 44px;
  min-height: 96vh;
}

.screen a {
  display: contents;
  text-decoration: none;
}
/* 
.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
} */

@media (min-width: 130em) {
  .main {
    padding: 130px 44px 44px 44px;
  }
}

:root {
  --background: #ffffff;
  --black: #000000;
  --border: #dbe9f5;
  --search: #9ea6ad;
  --gray: #8498ae;
  --gray-40: #9a9eb2;
  --gray-light: #a9b6c4;
  --gray-10: #ebecf2;
  --gray-green: #708680;
  --gray-green-light: #f2f6f2;
  --label: #172b3e;
  --label-bold: #232a2f;
  --background-green: rgb(7, 83, 61);
  --primary: #1da584;
  --primary-button: rgb(15, 201, 155);
  --primary-line: rgb(86, 116, 101);
  --primary-light-2: #1da5831f;
  --primary-light: #1da5840a;
  --red: red;

  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xs: 10px;
  --font-size-xl: 18px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 54px;
  --font-size-extra: 62px;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-roboto-mono: "Roboto Mono", monospace;
}
.x12px {
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 12px;
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.x14px {
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.gray-bold {
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.x28px-bold {
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 28px;
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
}

.x18px-bold {
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 18px;
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
}

@media (max-width: 55em) {
  .gray-bold {
    font-size: 14px;
    font-size: var(--font-size-m);
  }
}

@media (max-width: 30em) {
  .gray-bold {
    font-size: 12px;
    font-size: var(--font-size-s);
  }

  .x28px-bold {
    font-size: 18px;
    font-size: var(--font-size-xl);
  }

  .x14px {
    font-size: 10px;
    font-size: var(--font-size-xs);
  }
}

.button-form {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-form-hover:hover,
.button-form-hover:focus {
  text-decoration: underline;
}

.button:first-child {
  padding: 10px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--primary-light-2);
  border: 1px solid var(--primary-light-2);
  border-radius: 8px;
  padding: 6px;
  color: var(--primary);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.button:disabled,
.contactus-button:disabled,
.contactus-button-color:disabled {
  /* color: #ccc;
  background-color: var(--primary-light);
  border: none; */
  /* cursor: not-allowed; */
  opacity: 0.65;
}

.link {
  display: flex;
  width: 100%;
  background-color: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

.google-icon {
  width: 20px;
  margin-right: 26px;
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 34px;
}

.contactus-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-button);
  background-color: var(--primary-button);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  min-width: 100px;
  min-height: 50px;
  color: var(--background);
  font-size: var(--font-size-m);
  font-weight: 500;
  white-space: nowrap;
}

.contactus-button:hover {
  border-color: var(--primary) !important;
  background: var(--primary) !important;
  transition: all 0.25s ease-out;
  color: var(--gray-10) !important;
}

.contactus-button:active,
.contactus-button:focus,
.contactus-button:focus-within,
.contactus-button:focus-visible {
  border-color: var(--primary) !important;
  background: var(--primary) !important;
  color: var(--gray-10) !important;
  box-shadow: none !important;
  outline: none;
}

.spinner {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: var(--primary);
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
          appearance: textfield;
}

.title-auth {
  color: var(--gray);
  display: block;
  text-align: center;
  margin: 0 auto 20px auto;
}
.form-div {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30%;
}

.form {
  width: 100%;
  margin: 16px 0 6px;
}

.success-register {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.success-register-link,
.success-register-link:link {
  margin-left: 5px;
  /* text-decoration: none; */
  color: inherit;
  cursor: pointer;
}

.success-register-link:hover,
.success-register-link:focus {
  text-decoration: underline;
  color: inherit;
  font-weight: 800;
}

.input-div {
  width: 100%;
  margin-bottom: 30px;
}

.label {
  font: inherit;
}

.input-register,
.input-register:active,
.input-register:hover {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 2px solid var(--gray-light); */
  border-radius: 2px;
  background-color: inherit !important;
  background-image: none !important;
  font: inherit !important;
  color: inherit !important;
  padding: 6px 0;
  display: block;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
}

.error:focus {
  border-bottom: 3px solid var(--red);
}

.input-div-2 {
  display: flex;
  align-items: center;
}

.password-strength {
  margin-bottom: 20px;
}
.strength-validation {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  list-style: none;
  font-size: var(--font-size-m);
}

.green {
  color: var(--primary);
}

.gray {
  color: var(--gray);
}

.error-message {
  color: var(--red);
}

.forgot-password-button,
.msg-change-password {
  display: flex;
  justify-content: center;
  margin: 16px 0 2px;
}

@media (min-width: 130em) {
  .strength-validation {
    grid-gap: 20px;
    gap: 20px;
  }

  .title-auth {
    font-size: var(--font-size-xxxl) !important;
  }

  .label p {
    font-size: var(--font-size-xl) !important;
  }

  .input-register {
    font-size: var(--font-size-xl) !important;
  }
}

@media (max-width: 30em) {
  .input-register,
  .input-register:focus,
  .input-register:active,
  .input-register:hover {
    font-size: var(--font-size-s) !important;
  }

  input:-webkit-autofill::first-line {
    color: var(--gray);
    font-size: var(--font-size-xs);
    font-family: var(--font-family-roboto);
  }

  .strength-validation {
    font-size: var(--font-size-s);
  }

  .success-register {
    font-size: var(--font-size-s);
    display: block;
    text-align: center;
    padding: 50px;
  }

  .msg-change-password {
    font-size: var(--font-size-s);
    text-align: center;
  }
}
.msg-error {
  color: var(--red);
}

.input-div-2 {
  border-bottom: 2px solid var(--gray-light);
}

.icon {
  margin-right: 0;
}

.container-cadastro {
  background-color: var(--primary-light);
  margin: 0 auto;
  font-family: var(--font-family-roboto);
  color: var(--gray);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  padding-bottom: 30px;
  min-height: 110vh;
}
.container-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.div-logo {
  margin-bottom: 0px;
}

.logo-cadastro {
  display: block;
  max-width: 30%;
  margin: auto;
}

@media (max-width: 57em) {
  .logo-cadastro,
  .form-div {
    max-width: 50%;
  }
}

.perspective-container {
  -webkit-perspective: 800px;
          perspective: 800px;
}
.perspective-element {
  -webkit-transform: translateZ(0px) rotateX(25deg);
          transform: translateZ(0px) rotateX(25deg);
}

*,
::after,
::before {
  box-sizing: border-box;
}

video {
  object-fit: contain !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.lading-page {
  background-color: var(--background-green);
  margin: 0 auto;
  min-width: 32rem;
}

.navbar {
  align-items: center;
  display: flex;
  width: 100%;
  position: fixed !important;
  z-index: 50;
  padding: 0.5rem 1rem;
  background-color: var(--background-green) !important;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-brand {
  width: 180px;
  height: 58px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-right: 0 !important;
  font-size: var(--font-size-l) !important;
  color: var(--bs-navbar-brand-color) !important;
}

.navbar-brand-img {
  height: 100%;
  width: 100%;
}

.navbar .container {
  border-bottom: solid 1px var(--primary-line);
  padding: 20px 15px;
}

.container {
  width: 100%;
  padding: 44px 44px 44px 44px !important;
  max-width: 100% !important;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse:not(.show) {
  display: none;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.nav-link {
  display: block;
  font-size: var(--font-size-xl) !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--gray-light);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--gray-10);
}

.navbar-nav a {
  margin: 0 0 0 30px;
  cursor: pointer;
}

/* - - Product (Page)
========================================== */

/* - - - Landing
========================================== */
#cover {
  padding-top: 250px;
  background-size: cover;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 10px;
}

#cover .container,
#cover .navbar {
  position: relative;
  z-index: 2;
}

#cover-text {
  padding-top: 50px;
  padding-bottom: 150px;
  z-index: 2;
}

#cover-text > h1 {
  font-size: var(--font-size-xxxxl);
  font-weight: 200;
}

#cover-text > p {
  color: var(--gray-light);
  font-size: var(--font-size-xl);
}

.video-teaser-overlay-hr {
  position: absolute;
  top: 60%;
  left: 38%;
  width: 205%;
  height: 230%;
  background: linear-gradient(
    90deg,
    var(--background-green) 15%,
    rgb(7, 83, 61, 0) 45%,
    rgb(7, 83, 61, 0) 55%,
    var(--background-green) 80%
  );
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.video-teaser-overlay-vr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    180deg,
    var(--background-green) 20%,
    rgb(7, 83, 61, 0) 45%,
    rgb(7, 83, 61, 0) 55%,
    var(--background-green) 80%
  );
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

#video-teaser {
  position: absolute;
  top: 50%;
  left: 40%;
  width: 200%;
  height: 200%;
  z-index: -1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}

#image-teaser {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200% !important;
  max-width: 200% !important;
  z-index: -1;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}

#features {
  position: relative;
  background-size: cover;
  overflow: hidden;
  margin: 50px 0;
}

#features img {
  image-rendering: -webkit-optimize-contrast;
}

@media (max-width: 991.98px) {
  #cover {
    margin-bottom: 0px;
  }

  #cover-text {
    padding-bottom: 0px;
  }
  #video-teaser {
    position: relative;
    left: 53%;
    width: 150%;
    height: 150%;
    z-index: -1;
    opacity: 0.5;
  }

  #image-teaser {
    position: relative;
    width: 150%;
    max-width: 150% !important;
    z-index: -1;
    opacity: 0.5;
  }
}

#video {
  background: linear-gradient(#0b0b0f 60%, #ffffff 40%);
}

.category-block {
  padding: 15px 10px 0 10px;
}

.category-block:hover {
  background: var(--blue);
  transition: all 0.5s ease-out;
}

.category-block svg {
  fill: var(--gray);
  margin: 4px 15px 0 0;
  width: 48px;
  height: 48px;
}

.category-block:hover svg {
  fill: var(--white);
  transition: all 0.2s ease-out;
}

.category-block h6 {
  margin-bottom: 0;
}

.category-block:hover h6,
.category-block:hover p {
  color: var(--white) !important;
  transition: all 0.2s ease-out;
}

.category-block-stat {
  padding: 15px 10px 0 10px;
  margin-bottom: 50px;
}

.category-block-stat svg {
  margin: 4px 30px 0 0;
  width: 100px;
}

.category-block-stat-new {
  padding: 15px 10px 0 10px;
  margin-bottom: 50px;
}

.category-block-stat-new svg {
  margin: 0 30px 0 0;
  width: 70px;
  height: 70px;
}

.category-block-stat-new p.small {
  margin-bottom: 0;
}

.category-block-stat-new h6 {
  margin-bottom: 5px;
}

.category-block-stat h5 {
  margin-top: 50px;
  vertical-align: center;
}

.row-block-padding {
  padding: 80px 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 50px;
  padding-left: 15px;
}

/* Buttons */

.btn {
  font-size: var(--font-size-l) !important;
  padding: 10px 30px !important;
  border-radius: 2px !important;
  margin-bottom: 5px !important;
}

.btn-primary {
  border-color: var(--gray) !important;
  background: var(--gray) !important;
  color: var(--gray-10) !important;
}

.btn-primary:hover {
  border-color: var(--gray-40) !important;
  background: var(--gray-40) !important;
  transition: all 0.25s ease-out;
  color: var(--gray-10) !important;
}

.btn-primary:active,
.btn-primary:focus {
  border-color: var(--primary-button) !important;
  background: var(--primary-button) !important;
  color: var(--gray-10) !important;
  box-shadow: none !important;
}

/* Main Features section styles
========================================== */

.title-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title-custom h2 {
  color: var(--background);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  letter-spacing: 0.8px;
  line-height: 1.2;
  max-width: 540px;
  font-family: var(--font-family-roboto-mono);
}

.badge-info {
  font-size: var(--font-size-l);
  color: var(--gray-light);
  background: var(--gray-green);
  margin-bottom: 22px;
}

.badge-title {
  font-family: var(--font-family-roboto-mono);
  font-weight: 400;
  border-radius: 4px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.container-background {
  background-color: var(--primary-light);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  padding: 70px;
}

.text-big {
  font-size: var(--font-size-xl);
  font-weight: 400;
  color: var(--gray-light);
  margin-bottom: 20px;
  max-width: 540px;
}

/* countUp section styles
========================================== */
#countUp {
  overflow: hidden;
  margin: 50px 0 -100px 0;
}
.container.countUp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: var(--font-size-xxl);
  font-family: var(--font-family-roboto-mono);
  color: var(--gray-40);
}

.countUp-cnaes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  text-align: center;
  position: relative;
  top: -250px;
  left: 50px;
}

.countUp-cnaes.countUp-cnaes-2 {
  position: relative;
  left: -30px;
}

.countUp-cnaes span {
  font-size: var(--font-size-xxxl);
  color: var(--gray-green);
  transition: color 3s ease-in-out;
  font-weight: 600;
}

.countUp-item {
  position: relative;
  width: 350px;
}

.countUp-item-2 {
  position: relative;
  width: 350px;
  height: 350px;
  margin-left: -90px;
}

.iphone-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.gadgets-img {
  max-width: 400px;
}

/* Prototype section styles
========================================== */
.try-it-prototype {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.try-it-prototype h2 {
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  color: var(--gray-40);
  margin-bottom: 20px;
  font-family: var(--font-family-roboto-mono);
  word-wrap: break-word;
  max-width: 500px;
}

/* Footer section styles
========================================== */

#footer .container {
  padding-top: 50px;
  padding-bottom: 30px;
  border-top: solid 1px var(--primary-line);
}

#footer .flex {
  display: flex;
  flex-direction: row;
  grid-gap: 50px;
  gap: 50px;
  justify-content: flex-start;
}

#footer li {
  margin-bottom: 10px;
}

#footer .copyright {
  margin-top: 30px;
  color: var(--gray-green);
  font-size: var(--font-size-m);
  word-spacing: 2px;
}

#footer a {
  text-decoration: none;
}

#footer ul {
  font-size: var(--font-size-m);
}

#footer p {
  font-size: var(--font-size-l);
  color: var(--gray-light);
  margin-bottom: 8px;
}

#footer .icon {
  margin-right: 10px;
  width: 20px;
}

#footer .icon path {
  fill: var(--background);
}

@media (min-width: 130em) {
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1740px !important;
  }

  .navbar-brand {
    min-width: 250px;
    height: 120px;
  }

  .video-teaser-overlay-hr {
    -webkit-transform: translateX(-38%) translateY(-50%);
            transform: translateX(-38%) translateY(-50%);
    left: 17%;
  }

  .nav-link {
    font-size: var(--font-size-xxxl) !important;
  }

  #cover-text > h1 {
    font-size: var(--font-size-extra);
  }

  #cover-text > p,
  .btn,
  .badge-info,
  .text-big,
  #footer p {
    font-size: var(--font-size-xxl) !important;
  }

  .title-custom h2,
  .text-big,
  .try-it-prototype h2 {
    font-size: var(--font-size-xxxxl);
    max-width: 700px;
  }

  .countUp-item,
  .countUp-item-2 {
    width: 550px;
  }

  .countUp-item-2 {
    height: 550px;
  }

  .countUp-cnaes {
    top: -335px;
    left: 133px;
  }

  .countUp-cnaes.countUp-cnaes-2 {
    left: 54px;
  }

  #tryit > div > div > div > img {
    width: 480px;
  }

  #footer > div > div.flex > div:nth-child(1) > a > img {
    height: 80px;
  }

  #footer ul {
    font-size: var(--font-size-xl);
  }

  #footer .copyright {
    font-size: var(--font-size-xl) !important;
  }
}

@media (max-width: 992px) {
  .video-teaser-overlay-vr {
    height: 140%;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 425px) {
  .lading-page {
    max-width: 100% !important;
  }
  #cover {
    max-width: 100% !important;
    padding-top: 150px;
  }

  #cover-text {
    padding-top: 0;
  }

  #cover-text > h1 {
    font-size: var(--font-size-xxl);
  }

  .big {
    font-size: var(--font-size-m) !important;
  }

  .btn {
    font-size: var(--font-size-m) !important;
    padding: 8px 20px !important;
  }

  .title-custom h2 {
    font-size: var(--font-size-xl);
    min-width: 270px;
  }

  .text-big {
    min-width: 270px;
  }

  .badge-title {
    font-size: var(--font-size-m);
  }

  .container.countUp {
    font-size: var(--font-size-xl);
    flex-direction: column;
    align-items: center;
  }

  .countUp-cnaes {
    top: -200px;
    left: 20px;
  }

  .countUp-cnaes.countUp-cnaes-2 {
    top: -240px;
    left: 20px;
  }

  .countUp-cnaes p {
    max-width: 150px;
  }

  .countUp-item,
  .countUp-item-2 {
    margin-top: -50px;
    width: 300px;
  }

  .countUp-item-2 {
    position: relative;
    margin-left: 0;
    margin-top: -150px;
  }

  .try-it-prototype {
    flex-direction: column;
    align-items: center;
    text-align: flex-start;
    grid-gap: 30px;
    gap: 30px;
  }

  .try-it-prototype h2 {
    font-size: var(--font-size-xxl);
  }
  .try-it-prototype img {
    width: 80%;
  }

  .iphone-div {
    margin-right: 0;
  }

  .title-custom h2 {
    font-size: var(--font-size-m);
    min-width: 50px;
  }

  .iphone-div img {
    width: 40%;
  }

  #footer {
    max-width: 100% !important;
    font-size: var(--font-size-xs);
  }
  #footer ul {
    font-size: var(--font-size-xs);
    word-wrap: break-word;
  }
  #footer .container {
    padding-bottom: 20px;
  }
  #footer .copyright {
    font-size: var(--font-size-xs);
  }

  #footer .flex {
    max-width: 100% !important;
    grid-gap: 18px;
    gap: 18px;
  }
  .navbar {
    max-width: 100% !important;
  }

  .flex .navbar-brand img {
    width: 80px;
  }

  .flex .div-contact {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-teaser-overlay-vr {
    height: 140%;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 16px;
  top: 15px;
  left: initial;
  right: 20px;
  visibility: hidden;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--black);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--gray);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--gray);
}

.bm-cross-hover {
  background: black;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--search);
  padding: 2.5em 0;
  font-size: 1.15em;
  margin-left: 40px;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item,
.bm-item:active,
.bm-item:visited,
.bm-item:hover,
.bm-item:focus,
.bm-item:focus-visible {
  padding: 0.8em;
  color: var(--label);
  display: inline-block;
  text-decoration: none;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
}

.selected1 {
  color: var(--label-bold) !important;
  font-weight: 600;
}

.iconUser {
  color: var(--label);
  height: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Phone */

@media (max-width: 27em) {
  .bm-burger-button {
    visibility: visible;
  }

  .bm-menu-wrap {
    width: 200px !important;
  }
}

.dropdown {
  height: auto !important;
}

.user-active:hover path,
.user-active:focus path {
  fill: var(--primary);
  stroke-width: 0.2;
}

p {
  margin-bottom: 0;
}
.header-logo {
  min-width: 180px;
  height: 58px;
}

.show > .dropdown-menu {
  max-height: 900px !important ;
  visibility: visible !important;
}

.dropdown-menu {
  box-shadow: 0px 6px 7px #212b360a;
  /* inset: 64px -30px auto auto !important; */
  -webkit-transform: none !important;
          transform: none !important;
  border: none !important;
  max-height: 0 !important;
  visibility: hidden !important;
  transition: all 0.5s ease-in-out !important;
  overflow: hidden !important;
}

.dropdown-item {
  padding: 5px 20px;
  font-size: var(--font-size-m) !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--primary-light-2) !important;
  font-size: var(--font-size-m) !important;
}

.logo {
  min-width: 100%;
  height: 100%;
}

.header {
  align-items: center;
  background-color: var(--background);
  box-shadow: 0px 6px 7px #212b360a;
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 20px 44px;
  position: fixed;
  z-index: 50;
}

.menu {
  list-style: none;
  display: flex;
  align-items: center;
  grid-gap: 4.8rem;
  gap: 4.8rem;
}

.menu li a:link,
.menu li a {
  display: inline-block;
  text-decoration: none;
  font-size: var(--font-size-xl);
  transition: all 0.3s;
  color: var(--gray);
  cursor: pointer;
}

.menu li a:visited {
  color: var(--gray);
  text-decoration: none;
}

.menu li a:hover {
  color: var(--label-bold);
}

.menu li:hover {
  color: var(--label-bold);
}

.selected {
  /* margin-top: 8px; */
  color: var(--label-bold) !important;
  text-decoration: none !important;
}

.selected:after {
  content: "";
  display: block;
  bottom: -42px;
  left: 50%;
  position: relative;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 7px;
  width: 40px;
  border-radius: 10px 10px 0 0;
  background: var(--primary);
  margin-top: -8px;
}

.icon {
  height: 100%;
  color: var(--gray);
  width: 24px;
  height: 25px;
}

.divisor {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  height: 35px;
  -webkit-transform: rotate(0.28deg);
          transform: rotate(0.28deg);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.usuario {
  align-items: center;
  align-self: stretch;
  border: 1px none;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.usuario a {
  display: flex !important;
  grid-gap: 4px;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

/* Big Screen */

@media (min-width: 130em) {
  .header-logo {
    min-width: 230px;
    height: 100px;
  }

  .menu {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .menu li a {
    font-size: var(--font-size-xxl) !important;
  }

  .selected:after {
    bottom: -55px;
  }

  .usuario > svg:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
  }

  .usuario > svg:nth-child(2) {
    width: 16px !important;
    height: 16px !important;
  }
}

/* Tablet */

@media (max-width: 55em) {
  .header {
    padding: 10px 22px;
  }

  .header-logo {
    min-width: 140px;
    height: 42px;
  }

  .frame-1 > h1 {
    font-size: var(--font-size-m);
  }

  .menu {
    grid-gap: 1.6rem;
    gap: 1.6rem;
    padding-left: 10px;
  }

  .menu li a:link,
  .menu li a {
    font-size: var(--font-size-m);
  }

  .selected:after {
    bottom: -28px;
  }
}

/* Phone */

@media (max-width: 30em) {
  .header {
    padding: 12px 12px;
  }

  .header-logo {
    min-width: 80px;
    height: 26px;
  }

  .menu {
    grid-gap: 0.8rem;
    gap: 0.8rem;
  }

  .menu li a:link,
  .menu li a {
    font-size: var(--font-size-s);
  }

  .usuario a > svg {
    width: 10px;
  }

  .usuario a > p {
    font-size: var(--font-size-xs);
  }
}

.footer-main {
  color: var(--label);
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@media (min-width: 130em) {
  .footer-main {
    font-size: var(--font-size-xl);
  }
}

.chart-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px none;
  padding-top: 20px;
  width: 100%;
}

.chart-2 {
  border: 1px none;
  width: 90%;
  height: 300px;
}

.ranking {
  height: 500px;
}

/* text[name="6120502"] {
  fill: black !important;
  transform: translateX(180px) !important;
} */

.mystyle {
  fill: black !important;
  -webkit-transform: translateX(180px) !important;
          transform: translateX(180px) !important;
}
.recharts-tooltip-wrapper,
.recharts-default-tooltip {
  outline: none !important;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
}

@media (min-width: 130em) {
  .recharts-tooltip-wrapper,
  .recharts-default-tooltip {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 57em) {
  .chart-2 {
    height: 150px;
  }
}

.no-data-msg {
  padding: 0 20px 20px 20px;
  font-size: var(--font-size-l);
  font-family: inherit;
}

@media (max-width: 48rem) {
  .chart-2 {
    width: 95%;
  }
  .recharts-tooltip-wrapper,
  .recharts-default-tooltip {
    max-width: 200px !important;
  }
  .no-data-msg {
    font-size: var(--font-size-m);
  }
}

@media (min-width: 91rem) {
  .no-data-msg {
    font-size: var(--font-size-xl);
  }
}

#ym {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 500;
  color: var(--label);
}

.divSvg {
  position: relative;
  top: 0px;
  left: -282px;
}

.divSvg-container {
  display: flex;
  flex-direction: column;
  grid-gap: 200px;
  gap: 200px;
  position: absolute;
}

/* .svg-timeline {
  width: 100% !important;
} */

.svg-timeline {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  grid-gap: 20px;
  gap: 20px;
  top: 700px;
  left: 30px;
  width: 100% !important;
}

.rc-slider-handle:active,
.rc-slider-handle:hover,
.rc-slider-handle:focus,
.rc-slider-handle-click-focused:focus,
.rc-slider-handle-draggin {
  box-shadow: var(--gray-light) 0px 0px 0px 4px !important;
  border-color: var(--primary);
}

.rc-slider-handle {
  margin-top: 0;
}

.svg-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 5px 0;
}

.svg-timeline p {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 500;
  color: var(--label);
}

#svg-list svg {
  left: 10px;
  top: -5px;
}

.svg-brasil path,
.svg-title path {
  fill: none;
  stroke: black;
  pointer-events: all;
}

.svg-brasil path:hover {
  -webkit-transform-origin: 400px 400px;
          transform-origin: 400px 400px;
  -webkit-animation: aumenta ease-out 800ms;
          animation: aumenta ease-out 800ms;
  /* fill: white; */
}

@-webkit-keyframes aumenta {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.1) scaleY(1.1);
            transform: scaleX(1.1) scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
  }
}

@keyframes aumenta {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.1) scaleY(1.1);
            transform: scaleX(1.1) scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
  }
}

.ativos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.s-ativos {
  align-self: stretch;
  justify-self: stretch;
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  box-shadow: 0px 6px 4px #1f46580a;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.graph {
  align-items: stretch;
  border: 1px none;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.header-2,
.header-2-ranking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--border);
  grid-gap: 20px;
  gap: 20px;
  padding: 14px 16px;
  width: 100%;
}

.header-2-ranking {
  justify-content: flex-start !important;
}

.estabelecimentos-ativos,
.clientes-ativos,
.receita-total-por-cnae-12m,
.receita-mdia-por-es,
.receita-total-por-cnae-mensal,
.receita-mdia-por-es-1,
.estabelecimentos-do-brasil {
  color: var(--label);
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.frame-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.frame-1 > h1 {
  font-size: var(--font-size-xl);
  margin-bottom: 0;
  text-align: left;
  line-height: 1.2;
}

.frame-1 > p {
  font-size: var(--font-size-m);
}

.header-disclaimer {
  display: flex;
  align-items: center;
  position: relative;
}

.disclaimer-icon {
  width: 16px;
  height: 16px;
}

.disclaimer-icon path {
  fill: var(--gray-light) !important;
  opacity: 0.5;
}

.popper-custom {
  margin: 0 -10px !important;
}
.tooltip-custom {
  margin: 10px 0 !important;
  box-shadow: 4px 4px 3px var(--gray-light) !important;
  border-radius: 10px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 0.5px solid var(--border) !important;
  background-color: var(--background) !important;
  font-size: var(--font-size-m) !important;
  font-family: var(--font-family-roboto) !important;
  padding: 12px !important;
  width: 200px !important;
}

.frame-2-1 {
  border: 1px none;
  height: 38px;
  min-width: 38px;
}

.overlap-group {
  border-radius: 10px;
  height: 38px;
  position: relative;
  width: 38px;
}

.overlap-group :hover {
  background-color: var(--border);
}

.rectangle-1 {
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  box-shadow: 0px 10px 8px #1f46580a;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 820px;
  padding-top: 50px;
}

.img-iframe {
  padding: 20px 250px;
  width: 100%;
  height: 100%;
}

.img-chart svg {
  width: 612.51611px;
  height: 639.04297px;
  fill: none;
  stroke: black;
  stroke-width: 0.5;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.img-chart circle.AC {
  fill: SpringGreen;
}
.img-chart circle.AM {
  fill: DarkGreen;
}
.img-chart circle.RO {
  fill: MediumSeaGreen;
}
.img-chart circle.RR {
  fill: LimeGreen;
}
.img-chart circle.PA {
  fill: ForestGreen;
}
.img-chart circle.AP {
  fill: SeaGreen;
}
.img-chart circle.TO {
  fill: Lime;
}
.img-chart circle.MT {
  fill: Coral;
}
.img-chart circle.MS {
  fill: Tomato;
}
.img-chart circle.GO {
  fill: OrangeRed;
}
.img-chart circle.DF {
  fill: DarkOrange;
}
.img-chart circle.PR {
  fill: Blue;
}
.img-chart circle.SC {
  fill: DodgerBlue;
}
.img-chart circle.RS {
  fill: DeepSkyBlue;
}
.img-chart circle.SP {
  fill: LightCoral;
}
.img-chart circle.ES {
  fill: Salmon;
}
.img-chart circle.MG {
  fill: Crimson;
}
.img-chart circle.RJ {
  fill: DarkRed;
}
.img-chart circle.BA {
  fill: Chocolate;
}
.img-chart circle.SE {
  fill: Maroon;
}
.img-chart circle.AL {
  fill: Brown;
}
.img-chart circle.PE {
  fill: Sienna;
}
.img-chart circle.PB {
  fill: SaddleBrown;
}
.img-chart circle.RN {
  fill: Peru;
}
.img-chart circle.CE {
  fill: SandyBrown;
}
.img-chart circle.PI {
  fill: RosyBrown;
}
.img-chart circle.MA {
  fill: BurlyWood;
}

@media (max-width: 30em) {
  .img-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-chart > img {
    width: 270px;
  }
}

@media (max-width: 48em) {
  .frame-1 {
    grid-gap: 10px;
    gap: 10px;
  }

  .frame-1 > h1 {
    font-size: var(--font-size-l);
    line-height: 1.2;
  }

  .header-2 {
    grid-gap: 5px;
    gap: 5px;
  }
  .rectangle-1,
  .frame-2-1,
  .overlap-group {
    min-width: 28px;
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 130em) {
  .frame-1 > h1 {
    font-size: var(--font-size-xxl);
  }

  .disclaimer-icon {
    width: 24px;
    height: 24px;
  }
}


:root {
  --rs-input-focus-border: var(--primary);
  --rs-state-focus-shadow: 0 0 0 3px var(--primary-light-2);
  --rs-btn-link-text: var(--primary);
  --rs-btn-primary-bg: var(--primary);
  --rs-bg-active: var(--primary);
  --rs-calendar-cell-selected-hover-bg: var(--primary);
  --rs-calendar-range-bg: var(--primary-light-2);
  --rs-state-hover-bg: var(--primary-light-2);
  --rs-listbox-option-hover-text: var(--primary);
  --rs-listbox-option-hover-bg: var(--primary-light-2);
  --rs-text-primary: var(--label);
  --rs-btn-primary-hover-bg: var(--primary);
  --rs-btn-link-hover-text: var(--primary);
  --rs-picker-value: var(--primary);
  --rs-border-primary: var(--border);
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 12px;
}

.rs-picker-menu {
  font-size: 14px;
}

@media (min-width: 130em) {
  .rs-stack-item input,
  .rs-picker-toggle-placeholder {
    font-size: 22px !important;
    padding: 10px;
    height: 100%;
  }

  .rs-picker-daterange-header {
    font-size: 18px;
  }

  .rs-btn-xs,
  .rs-calendar-body,
  .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-content {
    font-size: 16px;
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 26px;
  }

  .rs-stack-item svg {
    font-size: 1.8rem;
  }
}

.option-1 {
  align-items: flex-start;
  border-style: none;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: var(--background);
}

.localidade-1 {
  align-items: center;
  border: 1px solid;
  border-color: var(--border);
  border-radius: 6px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  padding: 6px 0;
  width: 100%;
}

.css-1fdsijx-ValueContainer {
  display: flex !important;
}

.place {
  color: var(--label);
  font-weight: 400;
  margin-top: -1px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dropdown {
  height: 3px;
  min-width: 7px;
}

@media (min-width: 130em) {
  .localidade-1 {
    padding: 14px 14px;
  }
}

.filtro-daterangepicker {
  grid-column: 3/4;
}

.filtro-daterangepicker-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.datepicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

/* .filtro-daterangepicker-background {
  background-color: var(--gray-green-light);
  border-radius: 10px;
  /* padding: 6px 0 6px 6px; */
/* } */

.filter-icon {
  justify-self: flex-end;
  margin-right: 10px;
  font-size: 1.2em;
}

.filter-icon path {
  stroke: var(--primary);
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (min-width: 130em) {
  .filter-icon {
    font-size: 1.6em;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (max-width: 30em) {
  .filter-icon {
    margin-right: 0;
  }

  .datepicker,
  .filtro-daterangepicker-icon {
    grid-gap: 6px;
    gap: 6px;
  }
}

.modal-custome {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: var(--font-size-m);
  position: relative;
}

.modal-custome.modal-custome-show h1 {
  font-size: var(--font-size-xl);
}

.modal-custome-show {
  position: fixed;
  z-index: 500;
  width: 90%;
  left: 5%;
  top: 30%;
  transition: all 0.3s ease-out;
}

.modal-custome-ranking {
  position: absolute;
  width: 15%;
  z-index: 100;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: -webkit-transform 400ms ease 0s;
  transition: transform 400ms ease 0s;
  transition: transform 400ms ease 0s, -webkit-transform 400ms ease 0s;
}

@media (min-width: 130em) {
  .modal-custome {
    font-size: var(--font-size-xl);
  }

  .modal-custome-ranking {
    width: 16%;
  }
}

@media (max-width: 48em) {
  .modal-custome {
    font-size: var(--font-size-s);
  }

  .modal-custome-ranking {
    width: 40%;
  }
}

.main-scroll {
  max-width: 100%;
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-scroll-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.estabelecimentos-1 {
  border: 1px none;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 44px 44px 44px 0px;
}

.estabelecimentos-2 {
  align-items: center;
  border: 1px none;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.empresas {
  background-color: var(--primary);
  border: 1px none;
  border-radius: 16px;
  box-shadow: 1px 3px 3px #1da5843d;
  height: 38px;
  min-width: 6px;
}

.title-main {
  color: var(--label-bold);
  font-weight: 600;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.atualizacao {
  align-items: center;
  border: 1px none;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  padding: 0px 0 0 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.atualizado-em-29092022-1037 {
  color: var(--gray);
  font-weight: 400;
  margin-top: -1px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.buscar {
  display: grid;
  grid-template-columns: 10fr 1fr;
  align-items: center;
  border: 1px none;
  grid-gap: 10px;
  gap: 10px;
}

.busca {
  display: flex;
  align-items: center;
  grid-column: 1/3;
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}

.buscar-1 {
  border: 1px none;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  align-items: center;
}

.search-icon {
  border: 1px none;
  height: 25px;
  min-width: 24px;
  position: relative;
  -webkit-transform: rotate(-0.83deg);
          transform: rotate(-0.83deg);
}

.buscarPlaceholder,
.buscarPlaceholder :focus-visible {
  box-shadow: "none";
  width: 100%;
  outline: none;
  border: 0;
  min-height: 10px;
}

.buscarPlaceholder div,
.buscarPlaceholder div :focus-visible,
.buscarPlaceholder div :hover {
  border: 0;
  min-height: 10px !important;
}

.noOptionsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.css-tlfecz-indicatorContainer {
  padding: 0 8px !important;
}

.css-1s2u09g-control,
.css-1s2u09g-control:hover {
  min-height: 10px !important;
  /* border-color: red !important; */
  box-shadow: none !important;
}

@media (max-width: 55em) {
  .main {
    padding: 70px 12px;
  }

  .main-scroll-item {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
}

@media (max-width: 36em) {
  .main {
    padding: 26px 12px;
  }

  .busca {
    padding: 2px 6px;
  }

  .buscar {
    grid-gap: 0;
    gap: 0;
  }

  .buscar-1 {
    grid-gap: 2px;
    gap: 2px;
    /* max-width: 295px; */
  }

  .css-qbdosj-Input {
    display: none !important;
  }

  .main-scroll-item {
    grid-template-columns: minmax(0, 1fr);
  }
}

@media (min-width: 90em) {
  .title-main {
    line-height: 62px;
  }
}

@media (min-width: 130em) {
  .main-scroll-item {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    grid-row-gap: 40px;
    row-gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .estabelecimentos-1 {
    padding: 84px 44px 44px 0px;
  }

  .title-main {
    font-size: var(--font-size-xxxl);
  }

  .atualizado-em-29092022-1037 {
    font-size: var(--font-size-l);
  }

  .busca {
    padding: 20px 20px;
  }
}

.user-title > p {
  font-size: var(--font-size-xxl);
}

.contact-seawire-contact {
  display: flex;
  font-family: var(--font-family-roboto);
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px none;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.overlap-group1 {
  align-items: center;
  background-color: var(--background);
  display: flex;
  grid-gap: 112px;
  gap: 112px;
  padding: 0 60px 0 0;
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  max-width: 408px;
  min-width: 200px;
}

.image-custom {
  /* background-image: url(../../assets/contactus.jpeg); */
  background-color: var(--primary-light-2);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-filter: grayscale(40%);
          filter: grayscale(40%);
  background-blend-mode: screen;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.contact-us {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  width: 610px;
  min-width: 100px;
}

.title {
  color: var(--gray);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  margin-top: 12px;
  white-space: nowrap;
}

.first-name-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  margin-top: 20px;
  min-height: 68px;
  width: 100%;
}
.input-custom,
.input-custom:focus-visible,
.input-custom:hover {
  background-color: var(--gray-10);
  outline: none;
  color: var(--label);
}

::-webkit-input-placeholder {
  color: var(--gray-40);
}

::placeholder {
  color: var(--gray-40);
}

.first-name {
  width: 100%;
  border: none;
  white-space: nowrap;
}

.overlap-group-custom {
  align-items: flex-start;
  background-color: var(--gray-10);
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 15px;
}

.overlap-group-custom:focus-within {
  border-color: var(--primary-line);
  outline: none;
  border-color: var(--primary-line);
  box-shadow: 0 0 3px var(--primary-line);
}

.contact-us-item-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 12px;
  min-height: 68px;
  width: 100%;
}

.contact-us-item-1.message {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 24px;
  min-height: 152px;
  width: 100%;
}

.contact-us-item-1.message .overlap-group-1 {
  height: 120px;
  align-items: flex-start;
}

.roboto-normal-blueberry-16px {
  color: var(--primary-button);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-gray-chateau-16px {
  color: var(--gray-40);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.succsess-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.msg-success {
  color: var(--primary-button);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .overlap-group1 {
    grid-gap: 15px;
    gap: 15px;
  }

  .image-custom {
    min-width: 90%;
    height: 100%;
  }

  .contact-us {
    max-width: 300px;
  }
}
@media screen and (max-width: 980px) {
  .overlap-group1 {
    grid-gap: 15px;
    gap: 15px;
    padding: 0 60px 0 0;
  }
}

@media screen and (max-width: 600px) {
  .overlap-group1 {
    grid-gap: 15px;
    gap: 15px;
  }

  .image-custom {
    width: 85%;
    height: 100%;
  }
}

@media screen and (max-width: 570px) {
  .overlap-group1 {
    grid-gap: 10px;
    gap: 10px;
    padding: 0 30px 0 0;
  }

  .title {
    font-size: var(--font-size-xxl);
  }
  .contact-us {
    max-width: 180px;
  }

  .image-custom {
    width: 80%;
    height: 90%;
  }

  .contactus-button {
    min-width: 70px;
    min-height: 35px;
  }
}

@media screen and (max-width: 425px) {
  .overlap-group1 {
    flex-direction: column;
    padding: 10px 20px;
  }

  .image-placeholder {
    max-width: 200px;
    max-height: 200px;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .contact-us {
    max-width: 316px;
  }

  .overlap-group-custom {
    max-height: 40px;
  }

  .contact-us-item-1.message .overlap-group-1 {
    max-height: 100px;
  }

  .msg-success {
    font-size: var(--font-size-s);
  }
}

