.main-scroll {
  max-width: 100%;
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-scroll-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.estabelecimentos-1 {
  border: 1px none;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 44px 44px 44px 0px;
}

.estabelecimentos-2 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 16px;
  width: fit-content;
}

.empresas {
  background-color: var(--primary);
  border: 1px none;
  border-radius: 16px;
  box-shadow: 1px 3px 3px #1da5843d;
  height: 38px;
  min-width: 6px;
}

.title-main {
  color: var(--label-bold);
  font-weight: 600;
  text-align: center;
  width: fit-content;
}

.atualizacao {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 16px;
  padding: 0px 0 0 24px;
  width: fit-content;
}

.atualizado-em-29092022-1037 {
  color: var(--gray);
  font-weight: 400;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.buscar {
  display: grid;
  grid-template-columns: 10fr 1fr;
  align-items: center;
  border: 1px none;
  gap: 10px;
}

.busca {
  display: flex;
  align-items: center;
  grid-column: 1/3;
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}

.buscar-1 {
  border: 1px none;
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
}

.search-icon {
  border: 1px none;
  height: 25px;
  min-width: 24px;
  position: relative;
  transform: rotate(-0.83deg);
}

.buscarPlaceholder,
.buscarPlaceholder :focus-visible {
  box-shadow: "none";
  width: 100%;
  outline: none;
  border: 0;
  min-height: 10px;
}

.buscarPlaceholder div,
.buscarPlaceholder div :focus-visible,
.buscarPlaceholder div :hover {
  border: 0;
  min-height: 10px !important;
}

.noOptionsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.css-tlfecz-indicatorContainer {
  padding: 0 8px !important;
}

.css-1s2u09g-control,
.css-1s2u09g-control:hover {
  min-height: 10px !important;
  /* border-color: red !important; */
  box-shadow: none !important;
}

@media (max-width: 55em) {
  .main {
    padding: 70px 12px;
  }

  .main-scroll-item {
    column-gap: 10px;
  }
}

@media (max-width: 36em) {
  .main {
    padding: 26px 12px;
  }

  .busca {
    padding: 2px 6px;
  }

  .buscar {
    gap: 0;
  }

  .buscar-1 {
    gap: 2px;
    /* max-width: 295px; */
  }

  .css-qbdosj-Input {
    display: none !important;
  }

  .main-scroll-item {
    grid-template-columns: minmax(0, 1fr);
  }
}

@media (min-width: 90em) {
  .title-main {
    line-height: 62px;
  }
}

@media (min-width: 130em) {
  .main-scroll-item {
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .estabelecimentos-1 {
    padding: 84px 44px 44px 0px;
  }

  .title-main {
    font-size: var(--font-size-xxxl);
  }

  .atualizado-em-29092022-1037 {
    font-size: var(--font-size-l);
  }

  .busca {
    padding: 20px 20px;
  }
}
