.ativos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.s-ativos {
  align-self: stretch;
  justify-self: stretch;
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  box-shadow: 0px 6px 4px #1f46580a;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.graph {
  align-items: stretch;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.header-2,
.header-2-ranking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--border);
  gap: 20px;
  padding: 14px 16px;
  width: 100%;
}

.header-2-ranking {
  justify-content: flex-start !important;
}

.estabelecimentos-ativos,
.clientes-ativos,
.receita-total-por-cnae-12m,
.receita-mdia-por-es,
.receita-total-por-cnae-mensal,
.receita-mdia-por-es-1,
.estabelecimentos-do-brasil {
  color: var(--label);
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.frame-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.frame-1 > h1 {
  font-size: var(--font-size-xl);
  margin-bottom: 0;
  text-align: left;
  line-height: 1.2;
}

.frame-1 > p {
  font-size: var(--font-size-m);
}

.header-disclaimer {
  display: flex;
  align-items: center;
  position: relative;
}

.disclaimer-icon {
  width: 16px;
  height: 16px;
}

.disclaimer-icon path {
  fill: var(--gray-light) !important;
  opacity: 0.5;
}

.popper-custom {
  margin: 0 -10px !important;
}
.tooltip-custom {
  margin: 10px 0 !important;
  box-shadow: 4px 4px 3px var(--gray-light) !important;
  border-radius: 10px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 0.5px solid var(--border) !important;
  background-color: var(--background) !important;
  font-size: var(--font-size-m) !important;
  font-family: var(--font-family-roboto) !important;
  padding: 12px !important;
  width: 200px !important;
}

.frame-2-1 {
  border: 1px none;
  height: 38px;
  min-width: 38px;
}

.overlap-group {
  border-radius: 10px;
  height: 38px;
  position: relative;
  width: 38px;
}

.overlap-group :hover {
  background-color: var(--border);
}

.rectangle-1 {
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--border);
  border-radius: 10px;
  box-shadow: 0px 10px 8px #1f46580a;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 820px;
  padding-top: 50px;
}

.img-iframe {
  padding: 20px 250px;
  width: 100%;
  height: 100%;
}

.img-chart svg {
  width: 612.51611px;
  height: 639.04297px;
  fill: none;
  stroke: black;
  stroke-width: 0.5;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.img-chart circle.AC {
  fill: SpringGreen;
}
.img-chart circle.AM {
  fill: DarkGreen;
}
.img-chart circle.RO {
  fill: MediumSeaGreen;
}
.img-chart circle.RR {
  fill: LimeGreen;
}
.img-chart circle.PA {
  fill: ForestGreen;
}
.img-chart circle.AP {
  fill: SeaGreen;
}
.img-chart circle.TO {
  fill: Lime;
}
.img-chart circle.MT {
  fill: Coral;
}
.img-chart circle.MS {
  fill: Tomato;
}
.img-chart circle.GO {
  fill: OrangeRed;
}
.img-chart circle.DF {
  fill: DarkOrange;
}
.img-chart circle.PR {
  fill: Blue;
}
.img-chart circle.SC {
  fill: DodgerBlue;
}
.img-chart circle.RS {
  fill: DeepSkyBlue;
}
.img-chart circle.SP {
  fill: LightCoral;
}
.img-chart circle.ES {
  fill: Salmon;
}
.img-chart circle.MG {
  fill: Crimson;
}
.img-chart circle.RJ {
  fill: DarkRed;
}
.img-chart circle.BA {
  fill: Chocolate;
}
.img-chart circle.SE {
  fill: Maroon;
}
.img-chart circle.AL {
  fill: Brown;
}
.img-chart circle.PE {
  fill: Sienna;
}
.img-chart circle.PB {
  fill: SaddleBrown;
}
.img-chart circle.RN {
  fill: Peru;
}
.img-chart circle.CE {
  fill: SandyBrown;
}
.img-chart circle.PI {
  fill: RosyBrown;
}
.img-chart circle.MA {
  fill: BurlyWood;
}

@media (max-width: 30em) {
  .img-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-chart > img {
    width: 270px;
  }
}

@media (max-width: 48em) {
  .frame-1 {
    gap: 10px;
  }

  .frame-1 > h1 {
    font-size: var(--font-size-l);
    line-height: 1.2;
  }

  .header-2 {
    gap: 5px;
  }
  .rectangle-1,
  .frame-2-1,
  .overlap-group {
    min-width: 28px;
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 130em) {
  .frame-1 > h1 {
    font-size: var(--font-size-xxl);
  }

  .disclaimer-icon {
    width: 24px;
    height: 24px;
  }
}
