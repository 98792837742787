.option-1 {
  align-items: flex-start;
  border-style: none;
  display: flex;
  gap: 10px;
  min-width: fit-content;
  background-color: var(--background);
}

.localidade-1 {
  align-items: center;
  border: 1px solid;
  border-color: var(--border);
  border-radius: 6px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 6px 0;
  width: 100%;
}

.css-1fdsijx-ValueContainer {
  display: flex !important;
}

.place {
  color: var(--label);
  font-weight: 400;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.dropdown {
  height: 3px;
  min-width: 7px;
}

@media (min-width: 130em) {
  .localidade-1 {
    padding: 14px 14px;
  }
}
